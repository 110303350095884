import React, { FC, ReactNode, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useResizeDetector } from 'react-resize-detector';
import { ArrowLeftShadyIcon, ArrowRightIcon } from '@wix/wix-vod-shared/icons';
import styles from './CssSlider.scss';
import classNames from 'classnames';

type Accessibility = {
  containerMessage?: string;
  prevSlideMessage?: string;
  nextSlideMessage?: string;
};

type Props = {
  itemsGap?: number;
  isRTL: boolean;
  items: string[];
  itemsCount: number;
  renderItem(item: string): ReactNode;
  loadMore(count: number): void;
  a11y?: Accessibility;
  navButtonClassName?: string;
  navButtonsAbsolute?: boolean;
  showArrowsOnHover?: boolean;
  dataHook?: string;
};

const useNextItemIndex = ({
  children,
  scrollLeft,
  containerWidth,
  isRTL,
}: {
  children: HTMLDivElement[];
  scrollLeft: number;
  containerWidth: number;
  isRTL: boolean;
}): number => {
  let lastVisibleItemIndex = -1;

  for (let i = 0; i < children.length; i++) {
    const { offsetLeft, clientWidth } = children[i];

    const visible = isRTL
      ? offsetLeft + clientWidth + Math.abs(scrollLeft) > 0
      : offsetLeft < scrollLeft + containerWidth;

    if (visible) {
      lastVisibleItemIndex = i;
    } else {
      return lastVisibleItemIndex;
    }
  }

  return lastVisibleItemIndex;
};

const usePrevItemIndex = ({
  children,
  scrollLeft,
  containerWidth,
  isRTL,
}: {
  children: HTMLDivElement[];
  scrollLeft: number;
  containerWidth: number;
  isRTL: boolean;
}): number => {
  const approximateOffset = Math.abs(scrollLeft) - containerWidth;

  for (let i = 0; i < children.length; i++) {
    const { offsetLeft, clientWidth } = children[i];
    const offsetRight = containerWidth - offsetLeft - clientWidth;
    const offset = isRTL ? offsetRight : offsetLeft;

    if (offset >= approximateOffset) {
      return i;
    }
  }

  return -1;
};

const useNextVisible = ({
  containerWidth,
  scrollLeft,
  scrollWidth,
}: {
  containerWidth: number;
  scrollLeft: number;
  scrollWidth: number;
}) => {
  return scrollLeft + containerWidth < scrollWidth;
};

const useChildren = (container: HTMLDivElement | null): HTMLDivElement[] => {
  if (!container) {
    return [];
  }

  const { children } = container;
  return [...children] as HTMLDivElement[];
};

const NavButton: FC<{
  onClick(): void;
  className?: string;
  direction: 'left' | 'right';
  hidden: boolean;
  absolute?: boolean;
  ariaLabel?: string;
  showOnHover?: boolean;
  dataHook?: string;
}> = ({
  onClick,
  className,
  direction,
  hidden,
  absolute,
  ariaLabel,
  showOnHover,
  dataHook,
}) => {
  return (
    <button
      aria-label={ariaLabel}
      data-hook={dataHook}
      className={classNames(styles.navButton, className, {
        [styles.hidden]: hidden,
        [styles.absolute]: absolute,
        [styles.showOnHover]: showOnHover,
        [styles.arrowLeft]: direction === 'left',
        [styles.arrowRight]: direction === 'right',
      })}
      onClick={onClick}
    >
      {absolute ? (
        <ArrowLeftShadyIcon size="30px" />
      ) : (
        <ArrowRightIcon size="30px" />
      )}
    </button>
  );
};

export const CssSlider: FC<Props> = ({
  itemsGap = 0,
  isRTL,
  items,
  itemsCount,
  renderItem,
  loadMore,
  a11y,
  navButtonClassName,
  navButtonsAbsolute,
  showArrowsOnHover,
  dataHook,
}) => {
  const { width: containerWidth = 0, ref } = useResizeDetector<HTMLDivElement>({
    refreshMode: 'debounce',
    refreshRate: 300,
  });
  const container = ref.current;
  const scrollWidth = container?.scrollWidth || 0;
  const children = useChildren(container);
  const [scrollLeft, setScrollLeft] = useState(0);
  const nextItemIndex = useNextItemIndex({
    children,
    containerWidth,
    scrollLeft,
    isRTL,
  });

  const prevItemIndex = usePrevItemIndex({
    children,
    scrollLeft,
    containerWidth,
    isRTL,
  });

  const nextVisible = useNextVisible({
    scrollLeft,
    containerWidth,
    scrollWidth,
  });
  const visibleCount =
    nextItemIndex !== -1 && prevItemIndex !== -1
      ? nextItemIndex - prevItemIndex + 1
      : 0;

  const handleScroll = debounce((value: number) => {
    setScrollLeft(value);
  }, 300);

  const scrollToItem = (index: number) => {
    if (!container) {
      return;
    }

    const item = container.children[index] as HTMLDivElement | undefined;

    if (item) {
      const { offsetLeft, clientWidth } = item;

      if (isRTL) {
        const offsetRight = containerWidth - offsetLeft - clientWidth;
        container.scrollLeft = -offsetRight;
      } else {
        container.scrollLeft = offsetLeft;
      }
    }
  };

  const handleNextClick = () => scrollToItem(nextItemIndex);
  const handlePrevClick = () => scrollToItem(prevItemIndex);

  useEffect(() => {
    const loadedCount = items.length;

    if (loadedCount === itemsCount) {
      return;
    }

    if (loadedCount - nextItemIndex < visibleCount) {
      loadMore(visibleCount);
    }
  }, [items, itemsCount, loadMore, nextItemIndex, visibleCount]);

  return (
    <div className={styles.root} data-hook={dataHook}>
      <NavButton
        dataHook="css-slider-prev-button"
        onClick={handlePrevClick}
        absolute={navButtonsAbsolute}
        className={navButtonClassName}
        direction={isRTL ? 'right' : 'left'}
        hidden={scrollLeft === 0}
        ariaLabel={a11y?.prevSlideMessage}
        showOnHover={showArrowsOnHover}
      />
      <div
        data-hook="css-slider-slides"
        aria-label={a11y?.containerMessage}
        className={styles.slides}
        style={{ gap: itemsGap }}
        ref={ref}
        onScroll={(event) => handleScroll(event.currentTarget.scrollLeft)}
      >
        {items.map((item) => (
          <div key={item}>{renderItem(item)}</div>
        ))}
      </div>
      <NavButton
        dataHook="css-slider-next-button"
        onClick={handleNextClick}
        absolute={navButtonsAbsolute}
        className={navButtonClassName}
        direction={isRTL ? 'left' : 'right'}
        hidden={!nextVisible}
        ariaLabel={a11y?.nextSlideMessage}
        showOnHover={showArrowsOnHover}
      />
    </div>
  );
};
